<template>
  <v-app>
    <v-app-bar app color="#445566" elevation="5" outlined>
      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home' }">
          <v-img
            alt="AJAH-Solution"
            class="shrink mr-2"
            contain
            :src="require('@/assets/logo.png')"
            transition="scale-transition"
            width="164"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn
      color="white"
        dark
        right
        outlined
        rounded
        @click="isLanguage = !isLanguage"
       v-if="!isLanguage"
      >
        English
      </v-btn> -->
      <v-btn
      color="white"
        dark
        right
        outlined
        rounded
        @click="isLanguage = !isLanguage"
        v-if="isLanguage"
      >
        Nederlands
      </v-btn>
    </v-app-bar>
    <transition name="fade">
      <div class="lights" v-if="showSnow"></div>
      <div class="noLights" v-else></div>
    </transition>
    <Firework v-if="showFireworks" :boxHeight="'100%'" :boxWidth="'100%'" />
    <v-main>
      <router-view />
      <Snow :active="showSnow" :wind="2" :swing="4" />
    </v-main>
    <div class="lights-low" v-show="showSnow"></div>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "./components/Footer";
import Snow from "vue-niege";
import Firework from "./components/fireworks";

var format = require("date-format");

export default {
  name: "App",

  components: {
    Footer,
    Snow,
    Firework,
  },

  data: () => ({
    showSnow: false,
    showFireworks: false,
    timerCount_01: 30,
    isLanguage: false,
  }),
  watch: {
    timerCount_01: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount_01--;
          }, 1000);
        } else {
          this.timerCount_01 = 30;
          this.decoration();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created: function () {
    // `this` points to the vm instance
    this.decoration();
  },
  methods: {
    decoration: function () {
      var currentMonth = format.asString("MM", new Date());
      var currentDay = format.asString("dd", new Date());

      if ((currentMonth == 12) | (currentMonth == 1) | (currentMonth == 2)) {
        this.showSnow = true;
      }
      if (
        (currentMonth == 4 && currentDay == 17) |
        (currentMonth == 9 && currentDay == 22) |
        (currentMonth == 10 && currentDay == 17) |
        (currentMonth == 11 && currentDay == 3) |
        (currentMonth == 12 &&
          (currentDay == 24) | (currentDay == 25) | (currentDay == 31)) |
        (currentMonth == 1 && currentDay == 1)
      ) {
        this.showFireworks = true;
      }
    },
  },
};
</script>

// https://bestofvue.com/repo/P3trur0-vue-niege-vuejs-miscellaneous
