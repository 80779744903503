<template>
  <v-footer dark padless elevation="5">
    <v-card flat tile class="darkgrey white--text text-center col-12">
      <v-card-text class="white--text">
        <strong
          >Copyright © {{ new Date().getFullYear() }} — AJAH-Solution Developement and IT
          services</strong
        ><br />
        <v-btn
          v-for="(next, i) in links"
          :key="i"
          router-link
          :to="next.href"
          class="mr-1"
          plain
          small
          color="white"
        >
          {{ next.text }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    links: [
      {
        text: "Terms and Condictions",
        href: "/terms-and-condictions",
      },
      {
        text: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        text: "Cookie Policy",
        href: "/cookie-policy",
      },
    ],
  }),
};
</script>
